import * as React from "react"
import './AgataMeble.css';
import Creative from "../../components/Creative"
import backgroundAgata from "./images/agata_background.jpg"
import agataPatki from "./images/agata_patki.png"
import agataGosia from "./images/agata_gosia.png"
import video from "./images/agata_video.mp4"
import SimpleSidebar from "../../components/layout"

const AgataMeble = () => (
  <SimpleSidebar>
    <Creative
      className="agataMeble"
      video={video}
      background={backgroundAgata}
      heading="Apetyt na promocje"
      tags={["interaction", "animation"]}
      tagBackground="#f4a41d"
      tagColor="#ffffff"
      headingColor="#3e5f8b"
    >
      <img src={agataPatki} className="agata_image agataPatki"/>
      <img src={agataGosia} className="agata_image agataGosia"/>
    </Creative>
  </SimpleSidebar>
)

export default AgataMeble
